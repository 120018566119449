export const isLocal = () => {
  const LOCAL_DOMAINS = ['localhost', '127.0.0.1']
  return LOCAL_DOMAINS.includes(window.location.hostname)
}

export const isCancelMagicsnapPro = () => {
  const CANCEL_MAGICSNAP_DOMAINS = ['cancel.magicsnap.pro']
  return CANCEL_MAGICSNAP_DOMAINS.includes(window.location.hostname)
}

export const isStageMagicsnapPro = () => {
  const STAGE_MAGICSNAP_DOMAINS = ['stage.magicsnap.pro']
  return STAGE_MAGICSNAP_DOMAINS.includes(window.location.hostname)
}

export const isCancelUbodyFit = () => {
  const CANCEL_UBODY_FIT = ['cancel.ubody.fit']
  return CANCEL_UBODY_FIT.includes(window.location.hostname)
}

export const isCancelUbodyKz = () => {
  const CANCEL_UBODY_KZ = ['cancel.ubody.kz']
  return CANCEL_UBODY_KZ.includes(window.location.hostname)
}

export const isCancelUstretchSite = () => {
  const CANCEL_USTRETCH_SITE = ['cancel.ustretch.site']
  return CANCEL_USTRETCH_SITE.includes(window.location.hostname)
}

export const isCancelUbodySite = () => {
  const CANCEL_UBODY_SITE = ['cancel.ubody.site']
  return CANCEL_UBODY_SITE.includes(window.location.hostname)
}

export const isCancelUbodyApp = () => {
  const CANCEL_UBODY_APP = ['cancel.ubody.app']
  return CANCEL_UBODY_APP.includes(window.location.hostname)
}

export const isCancelUFoodSite = () => {
  const CANCEL_UFOOD_SITE = ['cancel.u-food.site']
  return CANCEL_UFOOD_SITE.includes(window.location.hostname)
}
